<template>
  <div
    class="as-container"
    :class="{ selected: selected, disabled: pendingStatus }"
    @mouseover="selected = true"
    @mouseleave="selected = false"
  >
    <v-row dense>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Visit"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="visit"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="HFID / Provider Number"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.providerNumber"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Invoice Number"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.invoiceNumber"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
      <v-text-field
        type="text"
        aria-label="Tag Number"
        class="asInput"
        outlined
        hide-details
        height="35px"
        v-model="assessment.tagNumber"
        :disabled="pendingStatus"
      />
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="text"
          aria-label="Description"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.description"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Statute"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.statute"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Part"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.part"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Subpart"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.subpart"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Item"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.item"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Subitem"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.subitem"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Assessment"
          class="asInput"
          outlined
          hide-details
          height="35px"
          v-model="assessment.assessment"
          :disabled="pendingStatus"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" lg="2">
        <v-autocomplete
            label="Scope and Level Code"
            v-model="assessment.scopeLevelCode"
            @input="scopeLevelChange()"
            :items="scopeCodes"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
            readonly
            label="Highest Scope"
            type="text"
            v-model="assessment.scope"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
            readonly
            label="Highest Level"
            type="text"
            v-model="assessment.level"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-textarea
            dense
            aria-label="Comment"
            class="asInput"
            outlined
            label="Comment"
            rows="1"
            v-model="assessment.comment"
        />
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-show="selected">
        <v-row class="toggle-delete-btn">
          <v-col>
            <v-btn
              v-if="!pendingStatus"
              class="toggle-delete-btn white--text"
              color="#b60000"
              @click="togglePending"
            >
              Delete
            </v-btn>
            <v-btn
              v-if="pendingStatus"
              class="toggle-delete-btn"
              color="#2a602c"
              @click="togglePending"
            >
              Undo Delete
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
  import {ScopeSeverity} from "@/shared/model/ScopeSeverity";

  export default {
    props: {
      assessment: Object,
      pendingStatus: Boolean
    },

    data() {
      return {
        selected: false,
        scopeSeverityList: ScopeSeverity.data
      }
    },

    methods: {
      togglePending() {
        this.$emit('togglePending', this.assessment.id)
      },

      scopeLevelChange() {
        this.assessment.scope = null
        this.assessment.level = null
        for(let i=0; i<this.scopeSeverityList.length; i++) {
          if (this.assessment.scopeLevelCode === this.scopeSeverityList[i].code) {
            this.assessment.scope = this.scopeSeverityList[i].scope
            this.assessment.level = this.scopeSeverityList[i].level
          }
        }
      },
    },

    computed: {
      visit: {
        get: function () {
          return this.assessment.evaluationNumber.split('-')[1]
        },
        set: function (newValue) {
          if (newValue) this.assessment.evaluationNumber = this.assessment.evaluationNumber.split('-')[0] + '-' + newValue
        }
      },

      scopeCodes() {
        return this.scopeSeverityList.map((item) => {
          return item.code
        })
      }
    }
  }
</script>

<style>
  .asInput .v-input__control .v-input__slot {
    min-height: auto !important;
    padding-left: 9px !important;
  }

  .asInput .v-input__control {
    padding: 10px 10px 5px !important;
  }

  .asInput:focus-within {
    border-style: none !important;
  }

  .v-btn.toggle-delete-btn {
    color: white;
  }

  .row + .row.toggle-delete-btn {
    margin-top: 0 !important;
  }

  .as-container {
    padding: 6px 0 12px;
  }

  .comment-box {
    padding: 0 20px;
  }

  .disabled {
    background: rgba(0, 0, 0, 0.1);
  }

  .selected {
    border: 2px solid #1d76d2;
    border-spacing: 25px;
  }
</style>