<template>
  <div class="home">
    <v-container fluid>
      <v-layout>
        <v-navigation-drawer
            style="height: 100%"
            permanent
        >
          <v-list>
            <v-list-item @click="screen='ExitInfo'">
              Exiting Information
            </v-list-item>
            <v-list-item @click="screen='Penalties'">
              List Of Penalties
            </v-list-item>
            <v-list-item @click="screen='Conditional'">
              Conditional
            </v-list-item>
            <v-list-item @click="screen='Scheduling'">
              Scheduling
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main v-if="screen" style="max-width: 85%" class="ml-5">
          <ExitingInfo :evaluation="evaluation" :exit-info="exitInfo" v-if="screen ==='ExitInfo'" />
          <Penalties :evaluation="evaluation" v-if="screen === 'Penalties'"/>
          <Conditional :evaluation="evaluation" v-if="screen ==='Conditional'" />
          <Scheduling :evaluation="evaluation" v-if="screen === 'Scheduling'" />
        </v-main>
      </v-layout>
    </v-container>



  </div>
</template>

<script>
import ExitingInfo from "./ExitingInfo";
import Penalties from "./Penalties";
import Conditional from "./Conditional";
import Scheduling from "./Scheduling";
import {evalApi} from "@/plugins/axios";

export default {
  components: {ExitingInfo, Penalties, Conditional, Scheduling},

  created() {
    const evalId = this.$route.params.evaluationId;
    if (evalId) {
      this.loadEvalData(evalId)
    } else {
      this.$router.push({name: 'EvaluationList'})
    }
  },

  data() {
    return {
      evaluation: {},
      exitInfo: {},
      screen:'Scheduling',
      projectNumber: ''
    }
  },

  methods: {
    loadEvalData(id) {
      evalApi.get(`/evaluationsImport/getEvaluation/` + id)
          .then((res) => {
            this.evaluation = res.data
            this.loadExitInfo(this.evaluation.evaluationNumber)
            this.projectNumber = this.evaluation.evaluationNumber.split('-')[0]
            this.evaluation.dueDate = this.formatDate(this.evaluation.dueDate)
            this.evaluation.endDate = this.formatDate(this.evaluation.endDate)
            this.evaluation.scheduleStart = this.formatDate(this.evaluation.scheduleStart)
            this.evaluation.scheduleEnd = this.formatDate(this.evaluation.scheduleEnd)
      })
    },

    loadExitInfo(evalNum) {
      evalApi.get(`/exit/get/`+ evalNum)
          .then((res) => {
            this.exitInfo = res.data
          })
    },

    formatDate(date) {
      return date != null ? new Date(date).toISOString().split('T')[0] : null
    }
  }

}
</script>

<style>

.v-input--radio-group legend.v-label {
  font-size: 18px;
  font-weight: bold;
}

.v-input__control {
  padding-top: 0px;
  padding-bottom: 0px;
}

</style>