<template>
  <div
    class="lic-container"
    :class="{ selected: selected, disabled: pendingStatus }"
    @mouseover="selected = true"
    @mouseleave="selected = false"
  >
    <v-row dense>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Visit"
          class="condInput"
          outlined
          hide-details
          height="35px"
          v-model="visit"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          type="text"
          aria-label="Duration"
          class="condInput"
          outlined
          hide-details
          height="35px"
          v-model="license.duration"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="date"
          aria-label="Enforcement Letter Sent Date"
          class="condInput"
          outlined
          hide-details
          height="35px"
          v-model="license.enfLtrSent"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="date"
          aria-label="Registered Nurse Practitioner Imposed Date"
          class="condInput"
          label="Imposed"
          outlined
          hide-details
          height="35px"
          v-model="license.consultStartDt"
          :disabled="pendingStatus"
        />
        <v-text-field
          type="date"
          aria-label="Registered Nurse Practitioner Lifted Date"
          class="condInput"
          label="Lifted"
          outlined
          hide-details
          height="35px"
          v-model="license.consultEndDt"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="date"
          aria-label="No New Substantiated Maltreatment Imposed Date"
          class="condInput"
          label="Imposed"
          outlined
          hide-details
          height="35px"
          v-model="license.subStartDt"
          :disabled="pendingStatus"
        />
        <v-text-field
          type="date"
          aria-label="No New Substantiated Maltreatment Lifted Date"
          class="condInput"
          label="Lifted"
          outlined
          hide-details
          height="35px"
          v-model="license.subEndDt"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="date"
          aria-label="No New Admissions Imposed Date"
          class="condInput"
          label="Imposed"
          outlined
          hide-details
          height="35px"
          v-model="license.adminStartDt"
          :disabled="pendingStatus"
        />
        <v-text-field
          type="date"
          aria-label="No New Admissions Lifted Date"
          class="condInput"
          label="Lifted"
          outlined
          hide-details
          height="35px"
          v-model="license.adminEndDt"
          :disabled="pendingStatus"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="date"
          aria-label="Other Imposed Date"
          class="condInput"
          label="Imposed"
          outlined
          hide-details
          height="35px"
          v-model="license.otherStartDt"
          :disabled="pendingStatus"
        />
        <v-text-field
          type="date"
          aria-label="Other Lifted Date"
          class="condInput"
          label="Lifted"
          outlined
          hide-details
          height="35px"
          v-model="license.otherEndDt"
          :disabled="pendingStatus"
        />
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-show="selected">
        <v-row class="comment-box mt-3">
          <v-textarea
              dense
              aria-label="Comment"
              class="asInput"
              outlined
              label="Comment"
              rows="2"
              v-model="license.comment"
          />
        </v-row>
        <v-row class="toggle-delete-btn">
          <v-col>
            <v-btn
                v-if="!pendingStatus"
                class="toggle-delete-btn white--text"
                color="#b60000"
                @click="togglePending"
            >
              Delete
            </v-btn>
            <v-btn
                v-if="pendingStatus"
                class="toggle-delete-btn"
                color="#2a602c"
                @click="togglePending"
            >
              Undo Delete
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
  export default {
    props: {
      license: Object,
      pendingStatus: Boolean
    },

    data() {
      return {
        selected: false
      }
    },

    methods: {
      togglePending() {
        this.$emit('togglePending', this.license.id)
      }
    },

    computed: {
      visit: {
        get: function () {
          return this.license.evaluationNumber.split('-')[1]
        },
        set: function (newValue) {
          if (newValue) this.license.evaluationNumber = this.license.evaluationNumber.split('-')[0] + '-' + newValue
        }
      }
    }
  }
</script>

<style>
  .condInput .v-input__control .v-input__slot {
    min-height: auto !important;
    padding-left: 9px !important;
  }

  .condInput .v-input__control {
    padding: 10px 10px 5px !important;
  }

  .condInput:focus-within {
    border-style: none !important;
  }

  .v-btn.toggle-delete-btn {
    color: white;
  }

  .row + .row.toggle-delete-btn {
    margin-top: 0 !important;
  }

  .lic-container {
    padding: 6px 0 12px;
  }

  .disabled {
    background: rgba(0, 0, 0, 0.1);
  }

  .selected {
    border: 2px solid #1d76d2;
    border-spacing: 25px;
  }
</style>